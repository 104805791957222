<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <h3>Usuarios</h3>
        </CCardHeader>
         <template>
              <CRow>
                <CCol col="3" class="ml-3 mt-1">
                  <router-link to="/mailing/usuarios/crear">
                    <CButton color="primary">Crear Nuevo Usuario</CButton>
                  </router-link>
                    
                </CCol>
              </CRow>   
         </template>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
            
          >
            <template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    @click="toggleDetails(item, index)"
                  >
                    {{Boolean(item._toggled) ? 'Ocultar' : 'Ver'}}
                  </CButton>
                </td>
              </template>
              <template #details="{item}">
                <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                  <CCardBody>
                      <p v-for="(valor, campo) in item" :key="campo">
                         <template v-if="validateShow(campo)">
                            <strong>{{ capitalizarCadaPalabra(campo) }} :</strong> {{ valor }}
                        </template>
                      </p>
                      <router-link :to="'/mailing/usuarios/editar/'+item.id_config">
                          <CButton size="sm" color="info" class="">
                            Editar Usuarios
                          </CButton>
                      </router-link>
                  </CCardBody>
                </CCollapse>
              </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import EventBus from '../../modules/bus';
export default {
  name: 'EditarUsuario',
  data () {
    return {
      items: [],
      fields: [
        { key: 'iduser', label: 'ID User', _classes: 'font-weight-bold' },
        { key: 'smtp_user',label:'Smtp User' },
        { key: 'limite_archivos',label:'Limite Archivos' },
        { key: 'limite_megas_archivos',label:'Limite Megas Archivos' },
        { key: 'maximo_boletines',label:'Maximo Boletines' },
        { key: 'maximo_grupos',label:'Maximo Grupos' },
        { key: 'fecha_creacion',label:'Fecha Creacion' },
        { key: 'activo',label:'Activo' },
        { key: 'show_details',label:'',
          _style: { width: '1%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1,
      collapseDuration: 0,
      columnas:[],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 1: return 'success'
        case 0: return 'danger'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getUsuarios(){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get(this.$httpUrlMail+'/mail/usuarios',{withCredentials:false}).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
                item['id_config'] = item['id'];
                if (id%2 == 0) {
                  item['_classes'] = 'table-now'; 
                }
                return item;
              })
             this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      return (formatterDolar.format(value)).replace('$','');
    },
    capitalizarCadaPalabra(frase) {
      // Dividir la frase en palabras
      frase = frase.replace(/_/g, ' ');
      let palabras = frase.split(' ');
      // Capitalizar la primera letra de cada palabra
      let palabrasCapitalizadas = palabras.map(function(palabra) {
        return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
      });
      // Unir las palabras capitalizadas de nuevo en una frase
      let fraseCapitalizada = palabrasCapitalizadas.join(' ');
      return fraseCapitalizada;
    },
    validateShow(campo){
       let columnasExcluidas = ['id','iduser','id_ruta','firma_envio','_classes','_toggled'];
        if (columnasExcluidas.includes(campo)) {
            return false;
        }else{
            return true;
        }
    }
  },
  mounted(){
    setTimeout(() => {
      this.getUsuarios();
    }, 200);
  },
  created: function() {
        EventBus.$on('mostrarNotificacion', (item) => {
          console.log(item);
            //this.comments.push(item)
        });
  }
}
</script>
